import {useContext} from 'react';
import {useQuery, useMutation} from 'react-query';
import {useNavigate} from 'react-router';
import {useForm, Controller} from 'react-hook-form';
import {Alert, Button, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {ArrowBack as ArrowBackIcon, Save as SaveIcon} from '@mui/icons-material';

import AuthContext from '../../context/auth';
import {fetchClient, updateClient} from '../../services';

import './Edit.css';

export default function ClientEdit() {
	const navigate = useNavigate();
	const {auth} = useContext(AuthContext);

	const {
		control,
		formState: {errors},
		handleSubmit,
		reset,
	} = useForm();

	const {isLoading, isError, error} = useQuery(
		['client', auth.selectedClient.id],
		() => fetchClient(auth.session.token, auth.selectedClient.id),
		{
			onSuccess: ({data: client}) =>
				reset({
					name: client.name,
					// FIXME: prefix address form field names, e.g. addressLine1
					line1: client.address?.line1,
					line2: client.address?.line2,
					city: client.address?.city,
					state: client.address?.state,
					postCode: client.address?.postCode,
					country: client.address?.country,
					email: client.email,
					notificationEmail: client.notificationEmail,
					phone: client.phone,
				}),
		},
	);

	const mutation = useMutation(['client', auth.selectedClient.id], client =>
		updateClient(auth.session.token, auth.selectedClient.id, client),
	);

	if (isLoading) {
		return <div>Loading...</div>;
	} else if (isError) {
		return <div>{error.message}</div>;
	}

	if (mutation.isSuccess) {
		return navigate('/client');
	}

	return (
		<div className="ClientEdit">
			{mutation.isError && <Alert severity="error">{mutation.error.message}</Alert>}

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="container">
					<div className="containerColumn">
						<div className="input">
							<Controller
								name="name"
								control={control}
								rules={{
									required: {value: true, message: 'Company name is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Company name"
										variant="outlined"
										error={!!errors.name}
										helperText={errors.name?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="line1"
								control={control}
								rules={{
									required: {value: true, message: 'Address is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Address"
										variant="outlined"
										error={!!errors.line1}
										helperText={errors.line1?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="line2"
								control={control}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label=""
										variant="outlined"
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="city"
								control={control}
								rules={{
									required: {value: true, message: 'City is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="City"
										variant="outlined"
										error={!!errors.city}
										helperText={errors.city?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="state"
								control={control}
								rules={{
									required: {value: true, message: 'State is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="State"
										variant="outlined"
										error={!!errors.state}
										helperText={errors.state?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="postCode"
								control={control}
								rules={{
									required: {value: true, message: 'Post code is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Post code"
										variant="outlined"
										error={!!errors.postCode}
										helperText={errors.postCode?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="country"
								control={control}
								rules={{
									required: {value: true, message: 'Country is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Country"
										variant="outlined"
										error={!!errors.country}
										helperText={errors.country?.message}
										{...field}
									/>
								)}
							/>
						</div>
					</div>

					<div className="containerColumn">
						<div className="input">
							<Controller
								name="phone"
								control={control}
								rules={{
									required: {value: true, message: 'Phone is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Phone"
										variant="outlined"
										error={!!errors.phone}
										helperText={errors.phone?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="email"
								control={control}
								rules={{
									required: {value: true, message: 'Email address is required'},
									pattern: {value: /^[^@]+@[^@]+$/, message: 'Email address is invalid'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Email"
										variant="outlined"
										error={!!errors.email}
										helperText={errors.email?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="notificationEmail"
								control={control}
								rules={{
									required: {value: true, message: 'Notification email address is required'},
									pattern: {value: /^[^@]+@[^@]+$/, message: 'Notification email address is invalid'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Notification email"
										variant="outlined"
										error={!!errors.notificationEmail}
										helperText={errors.notificationEmail?.message}
										{...field}
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="buttonRow">
					<span className="input">
						<Button startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
							Back
						</Button>
					</span>

					<span className="input">
						<LoadingButton
							type="submit"
							startIcon={<SaveIcon />}
							variant="contained"
							disabled={mutation.isLoading}
							loading={mutation.isLoading}
							loadingPosition="start"
						>
							Save
						</LoadingButton>
					</span>
				</div>
			</form>
		</div>
	);

	function onSubmit(data) {
		mutation.mutate({
			name: data.name,
			address: {
				line1: data.line1,
				line2: data.line2,
				city: data.city,
				state: data.state,
				postCode: data.postCode,
				country: data.country,
			},
			email: data.email,
			notificationEmail: data.notificationEmail,
			phone: data.phone,
		});
	}
}
