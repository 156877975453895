import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {AppBar, Container, Toolbar, Box, Button, Select, MenuItem} from '@mui/material';

import AuthContext from '../context/auth';

export default function TopNav() {
	const navigate = useNavigate();
	const {auth, isAuthenticated, hasAnyRole} = useContext(AuthContext);

	if (!isAuthenticated()) return null;

	return (
		<AppBar position="static">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Select value={auth.selectedClient?.id}>
						{auth.user.clients?.map(client => (
							<MenuItem key={client.id} value={client.id}>
								{client.name}
							</MenuItem>
						))}
					</Select>

					<Box
						sx={{
							flexGrow: 1,
							display: {xs: 'none', md: 'flex'},
						}}
					>
						<Button onClick={() => navigate('/dashboard')} sx={{my: 2, color: 'white', display: 'block'}}>
							Dashboard
						</Button>

						<Button onClick={() => navigate('/user')} sx={{my: 2, color: 'white', display: 'block'}}>
							User
						</Button>

						{hasAnyRole(['owner']) && (
							<Button onClick={() => navigate('/client')} sx={{my: 2, color: 'white', display: 'block'}}>
								Client
							</Button>
						)}

						<Button onClick={() => navigate('/menu')} sx={{my: 2, color: 'white', display: 'block'}}>
							Menu
						</Button>

						<Button onClick={() => navigate('/signout')} sx={{my: 2, color: 'white', display: 'block'}}>
							Sign out
						</Button>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
