import {Route, Routes} from 'react-router-dom';
import {QueryClient, QueryClientProvider} from 'react-query';

import {AuthContextProvider} from './context/auth';
import Protected from './components/Protected';
import TopNav from './components/TopNav';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import Menu from './pages/Menu';
import User from './pages/User';
import UserEdit from './pages/User/Edit';
import UserPassword from './pages/User/Password';
import Client from './pages/Client';
import ClientEdit from './pages/Client/Edit';
import ClientStripe from './pages/Client/Stripe';
import SignOut from './pages/SignOut';

import './App.css';

const queryClient = new QueryClient({
	defaultOptions: {queries: {refetchOnWindowFocus: false, retry: false}},
});

export default function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider>
				<TopNav />

				<Routes>
					<Route index element={<SignIn />} />

					<Route element={<Protected />}>
						<Route path="/dashboard" element={<Dashboard />} />
						<Route path="/user" element={<User />} />
						<Route path="/user/edit" element={<UserEdit />} />
						<Route path="/user/password" element={<UserPassword />} />
					</Route>

					<Route element={<Protected roles={['owner']} />}>
						<Route path="/client" element={<Client />} />
						<Route path="/client/edit" element={<ClientEdit />} />
						<Route path="/client/stripe" element={<ClientStripe />} />
						<Route path="/client/stripe/refresh" element={<ClientStripe stripeRefresh />} />
						<Route path="/client/stripe/return" element={<ClientStripe stripeReturn />} />
					</Route>

					<Route element={<Protected />}>
						<Route path="/menu" element={<Menu />} />
					</Route>

					<Route path="/signout" element={<SignOut />} />
					<Route path="*" element={<div>404 Not Found</div>} />
				</Routes>
			</AuthContextProvider>
		</QueryClientProvider>
	);
}
