import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import AuthContext from '../context/auth';

export default function SignIn() {
	const navigate = useNavigate();
	const {clearAuth} = useContext(AuthContext);

	useEffect(() => {
		clearAuth();
		navigate('/');
	}, [clearAuth]);

	return <div>Signing out...</div>;
}
