import axios from 'axios';

import WebError from '../WebError';

const api = axios.create({
	baseURL: window.API_ORIGIN,
	timeout: 10_000,
});

api.interceptors.request.use(
	config => config,
	err => Promise.reject(new Error(`Request error: ${err.message ?? err ?? 'Unknown error'}`)),
);

api.interceptors.response.use(
	res => res,
	err => {
		if (err.response?.status != null) {
			if (err.response.data != null) {
				return Promise.reject(new WebError(err.response.data.message, err.response.data.id));
			} else {
				return Promise.reject(new Error('No data'));
			}
		} else {
			return Promise.reject(err); // err should be an Error object (returned by axios)
		}
	},
);

export async function signin(email, password) {
	return api({
		method: 'post',
		url: '/auth',
		data: {email, password},
	});
}

export async function fetchClient(token, clientId) {
	return api({
		method: 'get',
		url: `/client/${clientId}`,
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
}

export async function fetchClientStripeAccount(token, clientId) {
	return api({
		method: 'get',
		url: `/client/${clientId}/pay/stripe/account`,
		headers: {
			authorization: `Bearer ${token}`,
		},
		// 404 = No acc num because not connected to Stripe
		validateStatus: status => (status >= 200 && status < 300) || status === 404,
	});
}

export async function fetchClientStripeAccountLink(token, clientId) {
	// FIXME: this is returning undef/nothing
	return api({
		method: 'post',
		url: `/client/${clientId}/pay/stripe/account`,
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
}

export async function updateClient(token, clientId, data) {
	return api({
		method: 'patch',
		url: `/client/${clientId}`,
		headers: {
			authorization: `Bearer ${token}`,
		},
		data,
	});
}

export async function fetchUser(token) {
	return api({
		method: 'get',
		url: '/user',
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
}

export async function updateUser(token, data) {
	return api({
		method: 'patch',
		url: '/user',
		headers: {
			authorization: `Bearer ${token}`,
		},
		data,
	});
}

export async function updatePassword(token, data) {
	return api({
		method: 'patch',
		url: '/user/password',
		headers: {
			authorization: `Bearer ${token}`,
		},
		data,
	});
}

export async function fetchMenu(token, clientId) {
	return api({
		method: 'get',
		url: `/client/${clientId}/menu`,
		headers: {
			authorization: `Bearer ${token}`,
		},
	});
}
