import {useContext} from 'react';
import {useMutation} from 'react-query';
import {useNavigate} from 'react-router';
import {useForm, Controller} from 'react-hook-form';
import {Alert, Button, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {ArrowBack as ArrowBackIcon, Save as SaveIcon} from '@mui/icons-material';

import AuthContext from '../../context/auth';
import {updatePassword} from '../../services';

import './Password.css';

export default function UserPassword() {
	const navigate = useNavigate();
	const {auth} = useContext(AuthContext);
	const mutation = useMutation(['user-password'], data => updatePassword(auth.session.token, data));

	const {
		control,
		formState: {errors},
		handleSubmit,
		getValues,
	} = useForm();

	if (mutation.isSuccess) {
		return navigate('/user');
	}

	return (
		<div className="UserPassword">
			{mutation.isError && <Alert severity="error">{mutation.error.message}</Alert>}

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="container">
					<div className="containerColumn">
						<div className="input">
							<Controller
								name="oldPassword"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'Old password is required',
									},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Old password"
										variant="outlined"
										error={!!errors.oldPassword}
										helperText={errors.oldPassword?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="newPassword"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'New password is required',
									},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="New password"
										variant="outlined"
										error={!!errors.newPassword}
										helperText={errors.newPassword?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="newPasswordConfirm"
								control={control}
								rules={{
									required: {
										value: true,
										message: 'New password confirmation is required',
									},
									validate: value => value === getValues().newPassword || 'Password does not match',
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="New password (type again to confirm)"
										variant="outlined"
										error={!!errors.newPasswordConfirm}
										helperText={errors.newPasswordConfirm?.message}
										{...field}
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="buttonRow">
					<span className="input">
						<Button startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
							Back
						</Button>
					</span>

					<span className="input">
						<LoadingButton
							type="submit"
							startIcon={<SaveIcon />}
							variant="contained"
							disabled={mutation.isLoading}
							loading={mutation.isLoading}
							loadingPosition="start"
						>
							Update
						</LoadingButton>
					</span>
				</div>
			</form>
		</div>
	);

	function onSubmit(data) {
		mutation.mutate({
			oldPassword: data.oldPassword,
			newPassword: data.newPassword,
		});
	}
}
