import {useContext} from 'react';
import {useQuery} from 'react-query';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router';
import {Edit as EditIcon, Payment as PaymentIcon} from '@mui/icons-material';

import AuthContext from '../../context/auth';
import {fetchClient} from '../../services';

export default function Client() {
	const navigate = useNavigate();
	const {auth} = useContext(AuthContext);

	const {
		isLoading,
		isError,
		data: {data: client} = {},
		error,
	} = useQuery(['client', auth.selectedClient.id], () => fetchClient(auth.session.token, auth.selectedClient.id));

	if (isLoading) {
		return <div>Loading...</div>;
	} else if (isError) {
		return <div>{error.message}</div>;
	}

	return (
		<div>
			<p>
				<strong>Name</strong>: {client.name}
			</p>
			<p>
				<strong>Address line 1</strong>: {client.address?.line1}
			</p>
			<p>
				<strong>Address line 2</strong>: {client.address?.line2}
			</p>
			<p>
				<strong>City</strong>: {client.address?.city}
			</p>
			<p>
				<strong>State</strong>: {client.address?.state}
			</p>
			<p>
				<strong>Post code</strong>: {client.address?.postCode}
			</p>
			<p>
				<strong>Country</strong>: {client.address?.country}
			</p>
			<p>
				<strong>Email</strong>: {client.email}
			</p>
			<p>
				<strong>Notification email</strong>: {client.notificationEmail}
			</p>
			<p>
				<strong>Phone</strong>: {client.phone}
			</p>

			<div>
				<Button variant="contained" startIcon={<EditIcon />} onClick={gotoEdit}>
					Edit
				</Button>

				<Button variant="contained" startIcon={<PaymentIcon />} onClick={gotoStripe}>
					Stripe
				</Button>
			</div>
		</div>
	);

	function gotoEdit() {
		navigate('/client/edit');
	}

	function gotoStripe() {
		navigate('/client/stripe');
	}
}
