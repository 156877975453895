import {useContext, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
// import {FormControl, TextField} from '@material-ui/core';
// import {Autocomplete} from '@material-ui/lab';

import AuthContext from '../../context/auth';
import {fetchMenu} from '../../services';
import {useMenuReducer} from '../../hooks';
// import Section from './Section';

export default function Menu() {
	const {auth} = useContext(AuthContext);
	// const [menus, setMenus] = useState();
	// const [menu, dispatchMenu] = useMenuReducer();

	const {
		isLoading,
		isError,
		data: {data: menu} = {},
		error,
	} = useQuery(['menu', auth.selectedClient.id], () => fetchMenu(auth.session.token, auth.selectedClient.id));

	if (isLoading) {
		return <div>Loading...</div>;
	} else if (isError) {
		return <div>{error.message}</div>;
	}

	return <pre>{JSON.stringify(menu, null, 2)}</pre>;

	// return (
	// 	<div>
	// 		<Autocomplete
	// 			options={menus}
	// 			size="small"
	// 			getOptionLabel={menu => menu.name}
	// 			value={menu}
	// 			renderInput={params => (<TextField {...params} label="Menu" variant="outlined" />)}
	// 			style={{width: '20em'}}
	// 			blurOnSelect={true}
	// 			noOptionsText="No menus found"
	// 			onChange={(event, menu) => {
	// 				dispatchMenu({type: 'set', value: menu});
	// 			}}
	// 		/>

	// 		{menu?.sections &&
	// 			<ul>
	// 				{menu.sections.map(section => (
	// 					<Section key={section._id} section={section} dispatchMenu={dispatchMenu} />
	// 				))}
	// 			</ul>
	// 		}
	// 	</div>
	// );
}
