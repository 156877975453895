import {useContext} from 'react';
import {useQuery, useMutation} from 'react-query';
import {useNavigate} from 'react-router';
import {useForm, Controller} from 'react-hook-form';
import {Alert, Button, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {ArrowBack as ArrowBackIcon, Save as SaveIcon} from '@mui/icons-material';

import AuthContext from '../../context/auth';
import {fetchUser, updateUser} from '../../services';

import './Edit.css';

export default function UserEdit() {
	const navigate = useNavigate();
	const {auth} = useContext(AuthContext);

	const {
		control,
		formState: {errors},
		handleSubmit,
		reset,
	} = useForm();

	const {isLoading, isError, error} = useQuery(['user'], async () => fetchUser(auth.session.token), {
		onSuccess: ({data: user}) =>
			reset({
				name: user.name,
				email: user.email,
			}),
	});

	const mutation = useMutation(['user'], user => updateUser(auth.session.token, user));

	if (isLoading) {
		return <div>Loading...</div>;
	} else if (isError) {
		return <div>{error.message}</div>;
	}

	if (mutation.isSuccess) {
		return navigate('/user');
	}

	return (
		<div className="UserEdit">
			{mutation.isError && <Alert severity="error">{mutation.error.message}</Alert>}

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="container">
					<div className="containerColumn">
						<div className="input">
							<Controller
								name="name"
								control={control}
								rules={{
									required: {value: true, message: 'Name is required'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Name"
										variant="outlined"
										error={!!errors.name}
										helperText={errors.name?.message}
										{...field}
									/>
								)}
							/>
						</div>

						<div className="input">
							<Controller
								name="email"
								control={control}
								rules={{
									required: {value: true, message: 'Email address is required'},
									pattern: {value: /^[^@]+@[^@]+$/, message: 'Email address is invalid'},
								}}
								render={({field}) => (
									<TextField
										InputLabelProps={{shrink: true}}
										fullWidth
										size="small"
										label="Email"
										variant="outlined"
										error={!!errors.email}
										helperText={errors.email?.message}
										{...field}
									/>
								)}
							/>
						</div>
					</div>
				</div>

				<div className="buttonRow">
					<span className="input">
						<Button startIcon={<ArrowBackIcon />} onClick={() => navigate(-1)}>
							Back
						</Button>
					</span>

					<span className="input">
						<LoadingButton
							type="submit"
							startIcon={<SaveIcon />}
							variant="contained"
							disabled={mutation.isLoading}
							loading={mutation.isLoading}
							loadingPosition="start"
						>
							Save
						</LoadingButton>
					</span>
				</div>
			</form>
		</div>
	);

	function onSubmit(data) {
		mutation.mutate({
			name: data.name,
			email: data.email,
		});
	}
}
