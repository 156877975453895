import {useContext, useEffect} from 'react';
import {useNavigate, Navigate, Outlet} from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthContext from '../context/auth';

export default function Protected({children, roles}) {
	const navigate = useNavigate();
	const {isAuthenticated, hasTokenExpired, hasAnyRole} = useContext(AuthContext);

	useEffect(() => {
		if (!isAuthenticated()) {
			// TODO: just show sign in page here, instead of redirecting?
			navigate('/', {replace: true});
		}
	}, [isAuthenticated]);

	if (!isAuthenticated()) {
		return <Navigate to="/" replace />;
	} else if (hasTokenExpired()) {
		return <Navigate to="/signout" replace />;
	} else if (roles != null && !hasAnyRole(roles)) {
		return <div>Not authorised</div>;
	} else {
		return children ?? <Outlet />;
	}
}

Protected.propTypes = {
	children: PropTypes.node,
	roles: PropTypes.arrayOf(PropTypes.string),
};
