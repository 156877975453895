import {useContext} from 'react';
import {useQuery} from 'react-query';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router';
import {Edit as EditIcon, Password as PasswordIcon} from '@mui/icons-material';

import AuthContext from '../../context/auth';
import {fetchUser} from '../../services';

export default function User() {
	const navigate = useNavigate();
	const {auth} = useContext(AuthContext);
	const {isLoading, isError, data: {data: user} = {}, error} = useQuery(['user'], () => fetchUser(auth.session.token));

	if (isLoading) {
		return <div>Loading...</div>;
	} else if (isError) {
		return <div>{error.message}</div>;
	}

	return (
		<div>
			<div>
				<p>
					<strong>Name</strong>: {user.name}
				</p>
				<p>
					<strong>Email</strong>: {user.email}
				</p>
			</div>

			<div>
				<Button variant="contained" startIcon={<EditIcon />} onClick={gotoEdit}>
					Edit
				</Button>

				<Button variant="contained" startIcon={<PasswordIcon />} onClick={gotoPassword}>
					Password
				</Button>
			</div>
		</div>
	);

	function gotoEdit() {
		navigate('/user/edit');
	}

	function gotoPassword() {
		navigate('/user/password');
	}
}
