import {useContext, useState} from 'react';
import {useQuery} from 'react-query';
import {Alert, AlertTitle, Button} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useNavigate} from 'react-router';
import {ArrowBack as ArrowBackIcon, Cable as CableIcon} from '@mui/icons-material';

import AuthContext from '../../context/auth';
import {fetchClientStripeAccount, fetchClientStripeAccountLink} from '../../services';

export default function ClientStripe(props) {
	const {stripeRefresh, stripeReturn} = props;
	const navigate = useNavigate();
	const {auth} = useContext(AuthContext);
	const [pageError, setPageError] = useState();
	const [stripeConnected, setStripeConnected] = useState(null); // null means hasn't been checked yet
	const [stripeConnectLoading, setStripeConnectLoading] = useState(false);

	const {
		data: {status, data: account} = {},
		isLoading,
		isError,
		error,
	} = useQuery(
		['stripeAccount', auth.selectedClient.id],
		() => fetchClientStripeAccount(auth.session.token, auth.selectedClient.id),
		{
			onSuccess: ({data: account}) => setStripeConnected(account.details_submitted && account.charges_enabled),
			cacheTime: 0,
		},
	);

	if (isLoading) {
		return <div>Loading...</div>;
	} else if (isError || pageError) {
		return (
			<Alert severity="error">
				<AlertTitle>Error</AlertTitle>
				{error.message ?? pageError}
				{error.id ? `(ID: ${error.id})` : ''}
			</Alert>
		);
	}

	return (
		<div className="ClientStripe">
			{pageError != null && (
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					{pageError}
				</Alert>
			)}

			{status === 200 && !stripeConnected && (
				<Alert severity="warning">
					<AlertTitle>Notice</AlertTitle>
					You have not yet completed the Stripe onboarding process. Please continue by clicking the Connect to
					Stripe button below. If you do not complete this process, your customers will be unable to pay using card
					at the checkout.
				</Alert>
			)}

			{renderStripeInfo({stripeConnected, status, account})}

			<div className="buttonRow">
				<span className="input">
					<Button
						startIcon={<ArrowBackIcon />}
						onClick={() => navigate(-1)}
						disabled={stripeReturn || stripeRefresh}
					>
						Back
					</Button>
				</span>

				<span className="input">
					<LoadingButton
						type="button"
						startIcon={<CableIcon />}
						variant="contained"
						disabled={stripeConnected || stripeConnectLoading}
						loading={stripeConnectLoading}
						loadingPosition="start"
						onClick={stripeConnect}
					>
						Connect to Stripe
					</LoadingButton>
				</span>
			</div>
		</div>
	);

	async function stripeConnect() {
		setStripeConnectLoading(true);

		try {
			const {data: accountLink} = await fetchClientStripeAccountLink(auth.session.token, auth.selectedClient.id);

			window.location.assign(accountLink.url);
		} catch (err) {
			setPageError(err.message);
			setStripeConnectLoading(false);
		}
	}
}

function renderStripeInfo({stripeConnected, status, account}) {
	if (stripeConnected) {
		return (
			<>
				<p>
					<strong>Status</strong>: Connected (Stripe account ID: {account.id}
				</p>

				<p>You are connected to Stripe.</p>
			</>
		);
	} else {
		return (
			<>
				{status === 200 && (
					<p>
						<strong>Stripe account ID</strong>: {account.id}
					</p>
				)}

				<p>
					To accept card payments, you need to sign up for a{' '}
					<a href="https://stripe.com" target="_blank" rel="noreferrer">
						Stripe
					</a>{' '}
					account, and connect it to your client account. This is very easy to do! Just click on the button below,
					and you&apos;ll be directed to the Stripe website.
				</p>

				<p>
					You can return here at any time, even if you haven&apos;t completed the onboarding process, by clicking
					the &quot;Return to Vinnerd Web Solutions Pty Ltd&quot; link on the left side of the page. Stripe will
					save your progress so that you can continue when you&apos;re ready.
				</p>
			</>
		);
	}
}
