import {createContext} from 'react';
import PropTypes from 'prop-types';

import {useLocalStorage} from '../hooks';

const AuthContext = createContext({
	// Default shape of context
	auth: null,
	setAuth: () => {},
	clearAuth: () => {},
	isAuthenticated: () => {},
	hasTokenExpired: () => {},
	hasAnyRole: () => {},
});

export function AuthContextProvider({children}) {
	const [auth, setAuth] = useLocalStorage('auth');
	const clearAuth = () => setAuth();
	const isAuthenticated = () => auth?.user != null;
	const hasTokenExpired = () => auth?.user == null || auth?.session?.expires < Date.now();
	const hasAnyRole = roles => roles != null && auth?.selectedClient?.roles?.some(userRole => roles.includes(userRole));

	return (
		<AuthContext.Provider value={{auth, setAuth, clearAuth, isAuthenticated, hasTokenExpired, hasAnyRole}}>
			{children}
		</AuthContext.Provider>
	);
}

AuthContextProvider.propTypes = {
	children: PropTypes.node,
};

export default AuthContext;
