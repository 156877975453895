import {useContext, useRef, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {Alert, Button, TextField} from '@mui/material';

import AuthContext from '../../context/auth';
import {signin, fetchUser} from '../../services';
import './index.css';

export default function SignIn() {
	const {setAuth, isAuthenticated} = useContext(AuthContext);
	const [error, setError] = useState();
	const emailRef = useRef();
	const passwordRef = useRef();

	if (isAuthenticated()) {
		return <Navigate to="/dashboard" replace />;
	}

	return (
		<div className="SignIn">
			{error && <Alert severity="error">{error.message}</Alert>}

			<form onSubmit={handleFormSubmit}>
				<TextField
					name="email"
					InputLabelProps={{shrink: true}}
					inputRef={emailRef}
					size="small"
					label="Email"
					variant="outlined"
					autoFocus
				/>

				<TextField
					name="password"
					InputLabelProps={{shrink: true}}
					inputRef={passwordRef}
					size="small"
					label="Password"
					variant="outlined"
					type="password"
				/>

				<Button type="submit" variant="contained">
					Sign in
				</Button>
			</form>
		</div>
	);

	async function handleFormSubmit(event) {
		event.preventDefault();

		try {
			const {data: session} = await signin(emailRef.current.value, passwordRef.current.value);
			const {data: user} = await fetchUser(session.token);

			// TODO: store/use selectedClient in auth localstorage.
			// TODO: just use 1st client for now
			// TODO: separate into a different localstorage key in future.
			setAuth({session, user, selectedClient: user.clients[0]});
		} catch (err) {
			setError(err);
		}
	}
}
